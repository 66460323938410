<template>
    <v-bottom-navigation v-model="value" bg-color="grey-darken-4" mode="shift" mandatory grow>
        <v-btn @click="navigateTo('/')">
            <v-icon>mdi-home</v-icon>
            <span>Home</span>
        </v-btn>


        <v-btn @click="navigateTo('/Contact')">
            <v-icon>mdi-headphones</v-icon>
            <span>Contact</span>
        </v-btn>
    </v-bottom-navigation>
</template>
  
<script>
export default {
    data() {
        return {

            value: 0,  // 如果这个值与另一个模块中的值相关，请注意同步它们
        };
    },
    computed: {
        color() {
            switch (this.value) {
                case 0: return 'blue-grey';
                case 1: return 'teal';
                case 2: return 'brown';
                case 3: return 'indigo';
                default: return 'blue-grey';
            }
        },
    },
    methods: {
        navigateTo(route) {
            this.$router.push(route);
        },
        navigateToAccount() {
            const UserID = localStorage.getItem('UserID');
            // 如果有 UserID，则导航到 /Account，否则导航到 /Login
            const route = UserID ? '/Account' : '/Login';
            this.$router.push(route);
        },
    },
};
</script>
<style>
.v-btn--selected {
    color: #fbc900;
}
</style>
