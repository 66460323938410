<template>
    <v-app-bar app class="d-flex" color="grey-darken-4">
        <v-btn icon="mdi-account" variant="tonal" @click="navigateToAccount"> </v-btn>
        <v-img src="https://bo.app96.cc/Icon/Logo.png"></v-img>
    </v-app-bar>

    <v-overlay v-model="overlay" contained style="position: fixed;">
        <v-layout>
            <v-navigation-drawer permanent absolute>
                <v-list class="text-center">
                    <v-avatar image="https://cdn.vuetifyjs.com/images/parallax/material.jpg" size="100"></v-avatar>
                    <div class="NavUserName">{{ username }}</div>
                    <div class="NavBalance">Balance:{{ balance }}</div>

                </v-list>

                <v-divider></v-divider>

                <v-list density="compact" nav>
                    <v-list-item prepend-icon="mdi-key-variant" title="My Files" value="Change Password"></v-list-item>
                    <v-list-item prepend-icon="mdi-history" title="Game History" value="shared"></v-list-item>
                    <v-list-item prepend-icon="mdi-logout" title="Logout" value="Logout" @click="logout"></v-list-item>
                </v-list>
            </v-navigation-drawer>
            <v-main style="height: 100vh;"></v-main>
        </v-layout>
        <!-- <v-btn color="success" @click="overlay = false">
            Hide Overlay
        </v-btn> -->
    </v-overlay>
</template>
  
<script>
import axios from 'axios';

export default {
    data() {
        return {
            overlay: false,
            username: localStorage.getItem('Username'),
            balance: '00.00',
        };
    },
    mounted() {
        // 在组件加载时获取余额数据
        if (this.username != null) { this.fetchBalance(); }
    },
    methods: {
        async fetchBalance() {
            axios.post('https://amoikiss.com/API/User/PlayerScore.php', { PlayerID: localStorage.getItem('UserID') }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then(response => {
                    this.balance = response.data.Score;
                });
        },
        navigateToAccount() {
            const UserID = localStorage.getItem('UserID');
            if (UserID) {
                this.overlay = !this.overlay
            } else {
                // const route = UserID ? '/Account' : ;
                this.$router.push('/Login');
            }
            // 如果有 UserID，则导航到 /Account，否则导航到 /Login
        },
        async logout() {
            localStorage.removeItem('UserID');
            localStorage.removeItem('GamePlayerID');
            localStorage.removeItem('Username');

            // 跳转到登录页面或其他适当的页面
            window.location.href = "/";

        }
    },
};
</script>
  
<style>
.logo {
    width: 3em;
}

.NavUserName {
    font-weight: 600;
    padding-top: 10%;
    transition: 0.3s;
}

.NavBalance {
    padding-bottom: 5%;
}

@media only screen and (max-width: 600px) {
    .Welcome {
        font-weight: 600;
        font-size: 0.8em;
    }
}

.HeaderStyle {
    background-color: #242424 !important;
}

.v-navigation-drawer.dialog-style {
    width: 80%;
    /* 设置宽度为 80% 或根据需要的宽度调整 */
    max-width: 400px;
    /* 设置最大宽度，避免在大屏幕上变得过宽 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* 添加阴影效果，使其看起来更像对话框 */
}
</style>
  