<template>
    <!-- <MyCarousel v-if="false" /> -->
    <v-container>
        <MyGameList />
    </v-container>
</template>
<script>

// import MyCarousel from '@/components/MyCarousel.vue';
import MyGameList from '@/components/MyGameList.vue';

export default {
    components: {
        MyGameList
        // 其他组件，如果有的话
    },

};

</script>