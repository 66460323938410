import { createRouter, createWebHistory } from 'vue-router'
import MyLogin from '@/view/MyLogin.vue'
import MyMain from '@/view/MyMain.vue'
import MyContact from '@/view/MyContact.vue'

const routes = [
    { path: '/', component: MyMain },
    { path: '/Login', component: MyLogin },
    { path: '/Contact', component: MyContact },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
