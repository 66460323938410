import { createApp } from 'vue';
import App from './App.vue';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import router from './router'; // 引入路由文件
import store from './store'; // 引入 Vuex store

const vuetify = createVuetify({
    components,
    directives,
});

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(store);

app.mount('#app');
