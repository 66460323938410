
<template>
  <v-app>

    <MyAppBar />
    <!-- <MyAppBar2 v-if="storedUserID != NULL" /> -->

    <v-main>

      <router-view></router-view>
    </v-main>

    <MyBottomNav />
  </v-app>
</template>

<script>
import axios from 'axios';
import MyAppBar from '@/components/MyAppBar.vue';
// import MyAppBar2 from '@/components/MyAppBar2.vue';
import MyBottomNav from '@/components/MyBottomNav.vue';

export default {
  components: {
    MyAppBar,
    // MyAppBar2,
    MyBottomNav,
  },
  data() {
    return {

      storedUserID: localStorage.getItem('UserID'),
    };
  },
  created() {

    if (this.storedUserID != null) {

      this.checkAccount();
    }

  },
  methods: {
    checkAccount() {
      const formData = new FormData();
      formData.append('PlayerID', this.storedUserID);
      axios.post('https://amoikiss.com/API/User/CheckPlayerStatus.php', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(response => {
          if (!response.data.success) {

            this.logout();
          }
        });
    },
    async logout() {
      localStorage.removeItem('UserID');
      localStorage.removeItem('GamePlayerID');
      localStorage.removeItem('Username');

      // 跳转到登录页面或其他适当的页面
      window.location.href = "/";

    }
  }

};
</script>

<style>
/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0px;
  /* 设置滚动条宽度 */
}

.no-padding {
  padding: 0 !important;
}

.v-application__wrap {
  background-color: rgb(43, 43, 43);
}
</style>
