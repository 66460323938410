<template>
    <v-card>
        <v-tabs v-model="tab" bg-color="deep-purple-accent-4" centered stacked grow>
            <v-tab value="tab-1">
                <v-img width="150" :aspect-ratio="1" src="https://amoikiss.com/Img/EpicWin_logo.png"
                    @click="fetchGames('EpicWin')"></v-img>
            </v-tab>

            <v-tab value="tab-2">
                <v-img width="150" :aspect-ratio="1" src="https://amoikiss.com/Img/Bigpot_logo.png"
                    @click="fetchGames('BigPot')"></v-img>
            </v-tab>
        </v-tabs>
    </v-card>
    <br>
    <v-text-field v-model="searchKeyword" label="Search Games" class="mx-4 mb-4 MyCard"></v-text-field>
    <!-- <span @click="navigateToLogin('vsgoldenkitty', 'BigPot')">bigpot</span> -->
    <!-- 游戏列表 -->
    <v-row>
        <v-col v-for="game in filteredGames" :key="game.id" class="d-flex child-flex" cols="3" md="2">
            <v-card style="width: 100%;" color="transparent" flat>
                <v-img :src="game.ImageUrl" aspect-ratio="1" :alt="game.GameName" cover
                    @click="navigateToLogin(game.GameCode, Game)">
                    <template v-slot:placeholder>
                        <v-row class="" align="center" justify="center" no-gutters>
                            <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                        </v-row>
                    </template>

                </v-img>
                <p class="text-center MyCard">
                    {{ game.GameName }}
                </p>
            </v-card>
        </v-col>
    </v-row>
    <v-overlay :model-value="overlay" class="align-center justify-center">
        <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>
  
<script>
import axios from 'axios';

export default {
    data() {
        return {
            Game: 'EpicWin',
            overlay: false,
            games: [],          // 保存原始游戏数据的数组
            searchKeyword: '',  // 搜索关键字
        };
    },

    mounted() {
        this.fetchGames(this.Game);  // 调用获取游戏数据的方法
    },

    computed: {
        // 根据搜索关键字过滤游戏列表
        filteredGames() {
            return this.games.filter(game => game.GameName.toLowerCase().includes(this.searchKeyword.toLowerCase()));
        },
    },

    methods: {
        getColumns() {
            // 這裡你可以根據窗口大小返回不同的列數
            // 例如，如果寬度小於某個值，返回 3，否則返回 2
            return window.innerWidth < 600 ? 3 : 2;
        },

        async fetchGames(gameListUrl) {
            this.Game = gameListUrl;
            try {
                this.overlay = true;
                const response = await axios.get('https://amoikiss.com/API/User/GetGameList.php?GameURL=' + gameListUrl);
                this.games = response.data.map(game => ({ ...game, loading: false }));

            } catch (error) {
                console.error('Error fetching games:', error);
            }
            finally {
                // 无论请求成功或失败，都会在最后将 loading 设置为 false，隐藏加载状态
                this.overlay = false;
            }
        },

        navigateToLogin(GameCode, Path) {
            const UserID = localStorage.getItem('UserID');
            const GamePlayerID = localStorage.getItem('GamePlayerID');

            if (!UserID) {
                this.$router.push('/Login'); // 导航到 /Login
            } else if (GamePlayerID) {
                // 显示加载状态
                this.overlay = true;
                // alert(GameCode);
                axios.post('https://amoikiss.com/API/User/PlayGame.php', { GamePlayerID: GamePlayerID, PlayerID: UserID, Path: Path, GameCode: GameCode }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                    .then(response => {
                        this.overlay = false;
                        if (response.data.Status) {
                            window.open(response.data.Url, '_blank');
                        } else {
                            alert(response.data.Message);
                        }
                    });
                // const Link = "https://amoikiss.com/API/EpicWin/GameLogin.php?GameCode=" + GameCode + "&PlayerId=" + GamePlayerID;
                // axios.get(Link, {
                //     headers: {
                //         'Content-Type': 'application/x-www-form-urlencoded',
                //     },
                // }).then(response => {
                //     // 隐藏加载状态
                //     this.overlay = false;

                //     if (response.data.Status == 200) {
                //         window.open(response.data.Url, '_blank');
                //     }
                //     // 在这里处理服务器的响应，如果有需要的话
                // })
            } else {
                alert("error");
            }
        },
    },
};
</script>
  
<style>
.MyCard {
    color: aliceblue;
    /* font-size:100%; */
}

.GameList {
    padding-left: 10%;
    padding-right: 10%;
}

@media only screen and (max-width: 600px) {
    .GameList {
        padding-left: 0;
        padding-right: 0;
    }
}

.custom-no-gutters {
    padding: 0 !important;
}

.v-col-md-2 {
    padding: 5px !important;
}
</style>
  