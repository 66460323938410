import { createStore } from 'vuex';

export default createStore({
    state: {
        showMyAppBar2: false,
    },
    mutations: {
        updateShowMyAppBar2(state, value) {
            state.showMyAppBar2 = value;
        },
    },
    // 其他配置
});
