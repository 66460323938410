<template>
    <v-card class="mx-auto my-8" width="90%" title="Vuetify on GitHub" prepend-icon="mdi-github"
        append-icon="mdi-open-in-new" href="#" target="_blank" rel="noopener"></v-card>
    <v-card class="mx-auto my-8" width="90%" title="Gmail" prepend-icon="mdi-gmail" append-icon="mdi-open-in-new" href="#"
        target="_blank" rel="noopener"></v-card>
    <v-card class="mx-auto my-8" width="90%" title="Facebook" prepend-icon="mdi-facebook" append-icon="mdi-open-in-new"
        href="#" target="_blank" rel="noopener"></v-card>
    <v-card class="mx-auto my-8" width="90%" title="Wechat" prepend-icon="mdi-wechat" append-icon="mdi-open-in-new" href="#"
        target="_blank" rel="noopener"></v-card>

    <v-card bg-color="deep-purple-accent-4">
        <v-card-text>
            <div class="font-weight-bold ms-1 mb-2">
                About Us
            </div>

            <v-timeline density="compact" align="start">
                <v-timeline-item v-for="message in messages" :key="message.time" :dot-color="message.color" size="x-small">
                    <div class="mb-4">
                        <div class="font-weight-normal">
                            <strong>{{ message.from }}</strong>
                        </div>
                        <div>{{ message.message }}</div>
                    </div>
                </v-timeline-item>
            </v-timeline>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        messages: [
            {
                from: 'INTRODUCTION',
                message: `At Joker, we offer a wide range of exciting products, from slot, roulette, table and card games for you to enjoy and
    discover countless fun and rewarding moments. Joker Online is known over the world for its user-friendly interface and
    stylish games, as well as customer service which surely will satisfy your needs and great promotions you'll enjoy. Our
    games are designed to meet the customers' diverse gaming habits and constantly updating ourselves to satisfy the
    cultural needs of our growing global membership.`,
                color: 'deep-purple-lighten-1',
            },
            {
                from: 'INNOVATION',
                message: `The goal is to appeal to all demographics of players by offering high quality internet casino gaming experience by using
    the best and latest technology in order to provide products with rich graphics, fantastic animations, interactive
    game-plays and immersive sound effects. Participate in Live Dealer Games for the ultimate thrill, the best
    adrenaline-pumping experience which you will not find anywhere else.`,
                color: 'green',
            },
            {
                from: 'INCREDIBLE ODDS & VALUE',
                message: ` Our goal is to raise your Online experience to the next level. Discover our progressive Jackpots, aggressive promotions
    and huge bonuses. At Joker, it is our commitment to maximize our players' interest, this makes us stand out from the
    crowd in the Internet Casino industry.`,
                color: 'deep-purple-lighten-1',
            },
            {
                from: 'SECURED HAVEN',
                message: ` Our services are available anytime, anywhere. We aim to create a convenient and entertaining environment.`,
                color: 'green',
            },
        ],
    }),
}
</script>
<style></style>
